import { Props } from "../../types";
import "./Landing.css";

import googlePlay from "../../assets/img/landing/google-play.svg";
import appStore from "../../assets/img/landing/app-store.svg";
import locationIcon from "../../assets/img/landing/svg/location.svg";
import emailIcon from "../../assets/img/landing/svg/email.svg";
import callIcon from "../../assets/img/landing/svg/call.svg";
import { Link, useNavigate } from "react-router-dom";
import { ADDRESS_MAP_LINK, AppConfig, CUSTOMER_APP_STORE_LINK, CUSTOMER_PLAY_STORE_LINK, FB_LINK, INSTA_LINK } from "../../config/constants";
import newIcon from "../../assets/img/landing/svg/new_fyk_icon_black.svg"
import { useEffect, useState } from "react";


function Landing(props: Props) {

    const restaurantLinks = [
        {
          name: "Royal Jasmine Mississauga",
          link: "/royal-jasmine-mississauga"
        },
        {
          name: "The Maharaja Mississauga",
          link: "/the-maharaja-mississauga"
        },
        {
          name: "Kailash Parbat Mississauga",
          link: "/kailash-parbat-mississauga"
        },
        {
          name: "Nirvana - The Flavours of India Mississauga",
          link: "/nirvana-the-flavours-of-india-mississauga"
        },
        {
          name: "Haritna Restaurant Mississauga",
          link: "/haritna-restaurant-mississauga"
        },
        {
          name: "Sankalp (Dixie) Mississauga",
          link: "/sankalp-(dixie)-mississauga-"
        },
        {
          name: "Dav's Hotspot Mississauga",
          link: "/dav's-hotspot-mississauga-"
        },
        {
          name: "Badshah Grill Dixie Mississauga",
          link: "/badshah-grill-dixie-mississauga"
        },
        {
          name: "Hakka Chilli Mississauga",
          link: "/hakka-chilli-mississauga"
        },
        {
          name: "Royal Tandoori Restaurant Mississauga",
          link: "/royal-tandoori-restaurant-mississauga"
        },
        {
          name: "Holy Wiches Mississauga",
          link: "/holy-wiches-mississauga"
        },
        {
          name: "Carinderia by DFlores Mississauga",
          link: "/carinderia-by-dflores-mississauga"
        },
        {
          name: "Bawarchi Mississauga",
          link: "/bawarchi-mississauga--mississauga"
        },
        {
          name: "Asli Shawarma Mississauga",
          link: "/asli-shawarma-mississauga"
        },
        {
          name: "Baitsitty Mississauga",
          link: "/baitsitty--mississauga"
        },
        {
          name: "Shawarma Kingdom Mississauga",
          link: "/shawarma-kingdom-mississauga"
        },
        {
          name: "Eat Meat Mediterranean Smokehouse & BBQ Mississauga",
          link: "/eat-meat-mediterranean-smokehouse&bbq-mississauga"
        },
        {
          name: "Aladdin's Shawarma Fusion Mississauga",
          link: "/aladdin's-shawarma-fusion-mississauga"
        },
        {
          name: "Karachi Roll Corner Mississauga",
          link: "/karachi-roll-corner-mississauga"
        },
        {
          name: "Tasty BBQ Mississauga",
          link: "/tasty-bbq-mississauga"
        },
        {
          name: "Bombay To Go Mississauga",
          link: "/bombay-to-go-mississauga"
        },
        {
          name: "Agha Turkish Cuisine Mississauga",
          link: "/agha-turkish-cuisine--mississauga"
        },
        {
          name: "Mela Kitchen Mississauga",
          link: "/mela-kitchen-mississauga"
        },
        {
          name: "Chef G Mississauga",
          link: "/chef-g-mississauga"
        },
        {
          name: "Karahi Master Mississauga",
          link: "/karahi-master--mississauga"
        },
        {
          name: "Carinderia Catering by DFlores Mississauga",
          link: "/carinderia-catering-by-dflores-mississauga"
        },
        {
          name: "Kusina Filipino Cuisine Mississauga",
          link: "/kusina-filipino-cuisine-mississauga"
        },
        {
          name: "Tandoori Boys Mississauga",
          link: "/tandoori-boys-mississauga-"
        },
        {
          name: "Bombay To Go (Catering) Mississauga",
          link: "/bombay-to-go-(catering)-mississauga"
        },
        {
          name: "Kusina Filipino Catering Mississauga",
          link: "/kusina-filipino-catering-mississauga"
        }
      ];

    const [isIOS, setIsIOS] = useState(false);

        useEffect(() => {
            // Check if the device is iOS
            const userAgent = navigator.userAgent || navigator.vendor;
            if (/iPhone|iPad|iPod/i.test(userAgent)) {
                setIsIOS(true);
            }
    }, []);
    
    return (
        <>
            <footer>
                <div className="container">
                    <footer className="py-5">
                        <div className="row">
                            <div className="col-md-4 col-lg-3 mb-3 ">
                                <h5>Contact Us</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2">
                                        <a href="/" className="nav-link p-0 text-body-secondary">{AppConfig.APP_NAME}</a>
                                    </li>
                                    <li className="nav-item mb-2">
                                        
                                        <a href={ADDRESS_MAP_LINK} className="nav-link p-0 text-body-secondary">
                                            <img src={locationIcon} width={"25px"} height={"29px"} />
                                            FYK Innovation INC <br/>
                                            140 Yonge St, 224, Toronto,<br/>
                                            Ontario, Canada, M2R 1X6<br/>
                                        </a>
                                    </li>
                                    <li className="nav-item mb-2">
                                        <a href="mailto:info@freeyourkitchen.com" className="nav-link p-0 text-body-secondary">
                                            <img src={emailIcon} width={"25px"} height={"25px"} />
                                            info@freeyourkitchen.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className=" col-md-4  col-lg-2 mb-3 col-6">
                                <h5>Free Your Kitchen</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2">
                                        <a href="/" className="nav-link p-0 text-body-secondary">About Us</a>
                                    </li>
                                    {/* <li className="nav-item mb-2">
                                        <a href="#" className="nav-link p-0 text-body-secondary">  Jobs</a>
                                    </li> */}
                                    <li className="nav-item mb-2">
                                        <a href="/join-as-restaurant" className="nav-link p-0 text-body-secondary"> Become a partner</a>
                                    </li>
                                    <li className="nav-item mb-2">
                                        <a href="/become-a-driver" className="nav-link p-0 text-body-secondary"> Join as a driver</a>
                                    </li>
                                </ul>
                            </div>
                            <div className=" col-md-4  col-lg-2 mb-3 col-6">
                                <h5>Support</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2">
                                        <Link to="/contact-us" className="nav-link p-0 text-body-secondary">Contact Us</Link>
                                    </li>
                                    <li className="nav-item mb-2">
                                        <Link to="/terms-and-conditions" className="nav-link p-0 text-body-secondary">Terms of service</Link>
                                    </li>
                                    <li className="nav-item mb-2">
                                        <Link to="/privacy-policy" className="nav-link p-0 text-body-secondary">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className=" col-md-4  col-lg-2 mb-3 col-6">
                                <h5>Follow Us</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2">
                                        <a href={FB_LINK} className="nav-link p-0 text-body-secondary">Facebook</a>
                                    </li>
                                    <li className="nav-item mb-2">
                                        <a href={INSTA_LINK} className="nav-link p-0 text-body-secondary">Instagram</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-4  col-lg-3 mb-3 col-6">
                                <h5>Download App</h5>
                                <ul className="nav flex-column">
                                    {/* Only show Google Play link if the device is not iOS */}
                                    {!isIOS && (
                                    <li className="nav-item mb-2">
                                        <a href={CUSTOMER_PLAY_STORE_LINK} style={{ textDecoration: "none" }} className="nav-link nav-icon">
                                            <img src={googlePlay}
                                                alt="Download now"
                                            />
                                        </a>
                                    </li>
                                    )}
                                    <li className="nav-item mb-2">
                                        <a href={CUSTOMER_APP_STORE_LINK} style={{ textDecoration: "none" }} className="nav-link nav-icon">
                                            <img
                                                src={appStore}
                                                alt="Download now"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-12 mb-12 ">
                                <h5>Check all the ongoing deals and exciting offers <a href="https://order-food.freeyourkitchen.com/"> here</a> !!</h5>
                            </div>
                        </div>
                        <div className="row">
                            <h6>Some of the restaurants on our platform from where you can order food online with free delivery and zero service fee</h6>
                        </div>
                       
                        <div className="row">
                            {restaurantLinks.map((restaurant, index) => (
                                <div key={index} className="col-md-4 col-lg-3 mb-3 col-6">
                                    <a className="nav-item mb-2" href={`https://order-food.freeyourkitchen.com/restaurant${restaurant.link}`}>
                                        {restaurant.name}
                                    </a>
                                </div>
                            ))}
                        </div>

                        <div className="d-flex flex-column justify-content-between  pt-4 pb-3 my-4 copy-write-wrapper">
                            <div>
                                <img src={newIcon} alt="" />
                            </div>

                            <h3 className="brand-name">Free Your Kitchen</h3>
                            <div className="copy-write">© {new Date().getFullYear()} Free Your Kitchen. All rights reserved.</div>
                        </div>
                    </footer>
                </div>
            </footer>
        </>
    );
}

export default Landing;
