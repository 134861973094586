import React, { useEffect } from "react";
import "./legal.css";
import useTitle from "../../hooks/useTitle";
import Landing from "./Landing";
const PrivacyPolicy = () => {
  useTitle("Privacy Policy");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div style={{ background: "#ffffff" }}>
      <main className="container legal-pages">
        <section className="text-justify">
          <h1>Privacy Policy</h1>
          <p>Free Your Kitchen and its affiliates (defined below) respect privacy, and the security of your
            Information (<b>“Information”</b>) and business information (<b>“Business Information”</b>) (<b>collectively,
              “Information”</b>) is important to us. We protect your Information by maintaining physical,
            organizational and technological safeguards appropriate to the sensitivity of your Information.
            Except as stated herein, Information may only be accessed by persons within our organization
            and other authorized third parties who require such access to provide you with the services
            indicated below.</p>
          <p>
            This Privacy Policy describes how 13640531 Canada Inc. doing business as Free Your Kitchen
            and its affiliates (<b>“we,”</b> <b>“us,”</b> or <b>“Free Your Kitchen”</b>), processes Information that we collect
            through the Free Your Kitchen Platform.
          </p>

        </section>
        <section>
          <h2>
            1. DEFINITIONS
          </h2>
          <p>
            Undefined terms in this Privacy Policy have the same definition as in our Terms of
            Service (<b>“Terms”</b>).
          </p>

          <h2>2. Information We Collect</h2>

          <h5>2.1 Information needed to use the Free Your Kitchen Platform.</h5>
          <p>
            We collect Information about you when you use the Free Your Kitchen Platform or when you
            provide it to us through other means (such as when you contact us or make a Order). Without this
            Information, we would be unable to provide you with all the services provided by the Free Your
            Kitchen Platform. This information includes:
          </p>
          <ul style={{ listStyle: "initial" }}>
            <li>
              <b> Contact Information, Account, Profile Information.</b> Such as your first name, last name,
              phone number, home address, email address, date of birth and passport details, some of
              which will depend on the services you use.
            </li>
            <li>
              <b>  Identity Verification, Background Checks and Payment Information.</b> Such as images
              of your government issued ID (as permitted by applicable laws), your ID number or other
              verification information, insurance documentation, any background checks relevant to
              your use of the Free Your Kitchen Platform, occupation, name of employer, previous
              addresses, social insurance number, bank account or payment account information,
              recordings of your communications with us and your transaction history.
            </li>
            <li>
              <b>  Other Information.</b> Such as any other information that is necessary for our identified
              purposes set out below and that is collected hereunder or as permitted by law.
            </li>

          </ul>
          <h5>2.2 Information voluntarily provided to us.</h5>
          <p>You may choose to provide us with additional Information. This information may include:</p>
          <ul style={{ listStyle: "initial" }}>
            <li>
              <b> Additional Profile Information..</b> Such as gender or preferred language, or any other field
              that is not a required field during your Free Your Kitchen account setup or during our
              information collection process or in the process of making an Order (please see Terms of
              Service for more information).
            </li>
            <li>
              <b> Other Information..</b> Such as when you fill in a form, add information to complete your
              Order, respond to surveys, participate in promotions, communicate with us and other
              Users, or share your experience with us. This may include health information if you choose
              to share it with us.
            </li>
          </ul>
          <h5>
            2.3 Automatically Collected Information
          </h5>
          <p>
            When you use the Free Your Kitchen Platform, Payment Services, and the Services, we
            automatically collect Information that is sent to us by your web browser. We use this information
            to better understand how users use the Free Your Kitchen Platform, and to improve the Free Your
            Kitchen Platform to better meet your needs. The amount of information sent to us by your web
            browser depends on the browser and the settings you use. Please refer to the instructions
            provided by your browser if you want to learn more about what information it sends to websites
            you visit, or how you may change or restrict this. This information may include:
          </p>
          <ul style={{ listStyle: "initial" }}>
            <li >
              <b>  Geo-location Information. </b> Such as precise or approximate location determined from
              your IP address or mobile device’s GPS depending on your device settings.
            </li>
            <li >
              <b> Usage Information. </b>  Such as the pages or content you view, searches for other User
              profiles, Orders you have placed, received or accepted for Delivery (as applicable to the
              relevant User), and other actions on the Free Your Kitchen Platform.
            </li>
            <li >
              <b> Log Data and Device Information. </b>  Such as details about how you’ve used the Free Your
              Kitchen Platform (including if you clicked on links to third party applications or vendors),
              IP address, access dates and times, hardware and software information, device
              information, device event information, unique identifiers, crash data, cookie data, and the
              pages you’ve viewed or engaged with before or after using the Free Your Kitchen Platform.
              We may collect this information even if you haven’t created an Account or logged in.
            </li>
            <li >
              <b>
                Cookies and Similar Technologies. Such as text files with small pieces of data, such
                as your username and password, that are used to identify your computer or mobile
                device as you access and use the Free Your Kitchen Platform. The Free Your
                Kitchen Platform uses “cookies” and other technologies to enhance your online
                experience, including personalizing the content that you see on the Free Your
                Kitchen Platform. Most web browsers can be set to disable the use of cookies.
                However, if you disable cookies, you may not be able to access certain features on
                the Free Your Kitchen Platform. We never place Information in any cookies.
              </b>
            </li>
            <li >
              <b>  Payment Transaction Information. </b>  Such as payment instrument used, date and time,
              payment amount, payment instrument expiration date and billing postcode, Stripe email
              address, banking email address, your address and other related transaction details.
            </li>
          </ul>
          <h5>
            2.4 Information Collected From Third Parties.
          </h5>
          <p>
            We collect Information from other sources, such as:
          </p>
          <ul style={{ listStyle: "initial" }}>
            <li>
              <b> Third-Party Services.</b> If you use any third-party services in connection with the Free Your
              Kitchen Platform, you direct the third-party to send us information such as your registration
              and profile information as controlled by that service or as authorized by you.
            </li>
            <li>
              <b> Background Information.</b> To the extent permitted by applicable laws and with your
              consent where required, we may obtain the local version of police, background, or other
              checks. We may use your information, including your full name and date of birth, to obtain
              such reports.
            </li>
            <li>
              <b> Additional Users.</b> If you are referred to Free Your Kitchen on behalf of another User, the
              User that referred you may be able to submit Information about you such as your name,
              age, home address, email address or other contact information.
            </li>
            <li>
              <b> Other Sources.</b> To the extent permitted by applicable law, we may receive additional
              information about you, such as demographic data or other information to help detect fraud
              and safety issues from third party service providers and/or partners, and combine it with
              information we have about you. For example, we may receive background check results
              or fraud warnings from identity verification service providers for use in our fraud prevention
              and risk assessment efforts. We may receive information about you and your activities on
              and off the Free Your Kitchen Platform, or about your experiences and interactions from
              our partners. We may receive health information, including but not limited to health
              information related to contagious diseases.
            </li>
          </ul>
        </section>
        <section>
          <h2>3. HOW WE USE INFORMATION WE COLLECT</h2>
          <h5>3.1 Provide, Improve, and Develop the Free Your Kitchen Platform. We use Information to:</h5>
        </section>
        <section>
          <h2>How We Use Your Information</h2>
          <ul style={{ listStyle: "initial" }}>
            <li>enable you to use the Free Your Kitchen Services and make and receive payments (as
              applicable to the User),
            </li>

            <li> supply you with requested services, facilitate placement of Orders, and present Service
              Offers to Deliverers
            </li>

            <li>approve Free Your Kitchen User accounts</li>

            <li>perform analytics, debug and conduct research,</li>

            <li>provide Services and respond to your inquiries,</li>

            <li>send you messages, updates, security alerts, and account notifications,</li>

            <li>verify identity and other information submitted by you or any third parties, for fraud
              detection and prevention,
            </li>

            <li>personalize and customize your experience based on your interactions with the Free Your
              Kitchen Platform and use of the Free Your Kitchen Services, your Order history and
              Delivery history (as applicable), your profile information and preferences, and other
              content you submit, and
            </li>

            <li>
              to carry out any other purposes that are disclosed to you and to which you consent.
            </li>

          </ul>
          <div className="d-flex gap-2">
            <h5>
              3.2 Create and Maintain a Trusted and Safer Environment.
            </h5>
            <h6 className="d-flex align-items-center "> We use the information to:</h6>
          </div>
          <ul style={{ listStyle: "initial" }}>
            <li>detect and prevent fraud, spam, abuse, security and safety incidents, and other harmful
              activity, </li>
            <li>conduct security investigations and risk assessments,</li>
            <li>verify or authenticate information provided by you,</li>
            <li>conduct checks against databases and other information sources, including background
              or police checks,</li>
            <li>comply with our legal obligations, protect the health and well-being of our employees and
              members of the public,</li>
            <li>resolve disputes between Users,</li>
            <li>enforce our agreements with third parties,</li>
            <li>comply with law, respond to legal requests, prevent harm and protect our rights (see
              section 4.5),</li>
            <li>perform quality assurance checks,</li>
            <li>enforce our <u>Terms</u> and other policies, and</li>
            <li>
              in connection with the activities above, we may conduct profiling based on your
              interactions with us, your profile information and other content you submit to Free Your
              Kitchen, and information obtained from third parties.
            </li>
          </ul>
          <div className="d-flex gap-2">
            <h5>3.3 Provide, Personalize, Measure, and Improve our Advertising and Marketing.</h5>
            <h6 className="d-flex align-items-center ">
              We may use Information to:
            </h6>
          </div>
          <ul>
            <li>send you promotional messages, marketing, advertising, and other information based on
              your preferences and social media advertising through social media platforms,</li>

            <li>personalize, measure, and improve our advertising,</li>

            <li>
              administer promotional programs, rewards, surveys, sweepstakes, contests, or other
              promotional activities or events sponsored or managed by Free Your Kitchen or its thirdparty partners,
            </li>

            <li>
              analyze characteristics and preferences to send you promotional messages, marketing,
              advertising and other information that we think might be of interest to you, and
            </li>

            <li>invite you to events and relevant opportunities.</li>

          </ul>
          <div className="d-flex gap-2">
            <h5>3.4 Provide Payment services.</h5>
            <h6 className="d-flex align-items-center ">
              Information is used to enable or authorize third parties to:
            </h6>
          </div>
          <ul style={{ listStyle: "initial" }}>

            <li>
              provide Payment Services,
            </li>
            <li>
              detect and prevent money laundering, fraud, abuse, identity theft and security incidents.
            </li>
            <li>
              conduct security investigations and risk assessments,
            </li>
            <li>
              comply with legal obligations,
            </li>
            <li>
              enforce our <u>Terms of Service</u> and other payment policies,
            </li>
            <li>
              with your consent, send you promotional messages, marketing, advertising, and other
              information that may be of interest to you based on your preference, and
            </li>

            <li> provide and improve the Payment Services.</li>

          </ul>
        </section>

        <section>
          <h2>4. SHARING, DISCLOSURE & RETENTION</h2>
          <h5>4.1 Sharing With Third Parties.</h5>
          <p>
            We may share your information with third parties, such as when authorizing a third-party
            application or website to access your Information or participating in promotional activities by Free
            Your Kitchen partners or third parties.
          </p>
          <p>
            Where permissible with applicable law, we may use certain information about you, such as your
            email address, transaction data and credit data, de-identify it (where required by applicable law),
            and share it with social media platforms, to generate leads, drive traffic to Free Your Kitchen or
            otherwise promote our products and services, and third-party resellers, to deliver data and
            products or access personal data and Collective Content.
          </p>
          <h5>4.2 Sharing Between Users.</h5>
          <p>To help facilitate Orders or other interactions between Customers, Restaurants and Deliverers,
            we may need to share certain Information such as:</p>
          <ul style={{ listStyle: "initial" }}>

            <li>
              When an Order request is submitted, certain information may be shared with Restaurants
              and Deliverers, including Customer’s full name, names of any additional Customers, home
              address, phone number, email address, dispute outcome (when applicable) and other
              information you choose to share and submit.
            </li>
            <li>
              When an Order is confirmed, cancelled, additional information is shared to assist with
              completing your Order, such as your full name and address, Order details, Order date,
              Restaurant name, Deliverer name, details of any Services and other related information.
            </li>

          </ul>
          <h5>
            4.3 Images and Promotional Materials.
          </h5>
          <p>
            We may display Promotional Materials and other Collective Content you make with your likeness
            on third-party sites, platforms and apps. Some of this information may be indexed through thirdparty search engines.
          </p>
          <h5>
            4.4 Third Party .
          </h5>
          <p>
            Free Your Kitchen may use third-party service providers to help manage or deliver their services
            on the Free Your Kitchen Platform. User Information uploaded on the Free Your Kitchen Platform
            (such as Order details, names, addresses and contact details) may be shared with such thirdparty service providers as well, at Free Your Kitchen’s sole discretion.
          </p>
          <h5>
            4.5 Complying with Law.
          </h5>
          <p>
            We may disclose your information to courts, law enforcement, governmental or public authorities,
            tax authorities, or authorized third parties, if and to the extent we are required or permitted to do
            so by law or where disclosure is reasonably necessary: (i) to comply with our legal obligations, (ii)
            to comply with a valid legal request or to respond to claims asserted against Free Your Kitchen,
            (iii) to respond to a valid legal request relating to a criminal investigation to address alleged or
            suspected illegal activity, or to respond to or address any other activity that may expose us, you,
            or any other of our users to legal or regulatory liability, (iv) to enforce and administer
            our agreements with Users, or (v) to protect the rights, property or personal safety of Free Your
            Kitchen, its employees, its Users, or members of the public. For example, if permitted due to the
            forgoing circumstances, User tax information may be shared with tax authorities or other
            governmental agencies.
          </p>
          <p>
            Where appropriate, we may notify Users about legal requests unless: (i) providing notice is
            prohibited by the legal process itself, by court order we receive, or by applicable law, or (ii) we
            believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an
            individual or group, or create or increase a risk of fraud upon or harm to Free Your Kitchen, our
            Users, or expose Free Your Kitchen to a claim of obstruction of justice.
          </p>
          <p>
            For jurisdictions where Free Your Kitchen facilitates the collection and remittance of Taxes where
            legally permissible according to applicable law, we may disclose information about transactions,
            Orders, Services provided and Taxes to the applicable tax authority, such as User names,
            addresses, addresses of any Orders, transaction dates and amounts, tax identification number(s),
            the amount of taxes received (or due) from Users, and contact information.
          </p>
          <p>
            Some or all of the Information we collect may be stored or processed in jurisdictions outside of
            Canada. As a result, this information may be subject to access requests from courts, law
            enforcement, governmental or public authorities, tax authorities or authorized third parties in those
            jurisdictions according to laws in those jurisdictions.
          </p>
          <h5>4.6 Retention of Information.</h5>
          <p>
            We retain Information that we collect only as long as necessary for the purposes for which it was
            collected or to meet legal requirements (see Section 6.3). We destroy Information when it is no
            longer needed.
          </p>
          <h5>4.7 Service Providers.</h5>
          <p>
            We may share Information with affiliated and unaffiliated service providers to help us run our
            business, including service providers that help us: (i) verify your identity or authenticate your
            identification documents, (ii) check information against public databases, (iii) conduct background
            checks, fraud prevention, and risk assessment, (iv) perform product development, maintenance
            and debugging, (v) allow the provision of the Free Your Kitchen Services through third-party
            platforms and software tools, (vi) provide customer service, advertising, or payments services, or
            (vii) process, handle or assess insurance claims or similar claims. These providers have access
            to your Information to perform these tasks.
          </p>
          <h5>
            4.8 Business Transfers.
          </h5>
          <p>
            If Free Your Kitchen undertakes or is involved in any merger, acquisition, reorganization, sale of
            assets, bankruptcy, or insolvency event, then we may sell, transfer or share some or all of our
            assets, including your information in connection with such transaction or in contemplation of such
            transaction (i.e., due diligence). In this event, we will notify you before your Information is
            transferred and becomes subject to a different privacy policy.
          </p>
        </section>
        <section>
          <h2>5. OTHER IMPORTANT INFORMATION</h2>
          <h5>5.1 Analyzing your Communications.</h5>
          <p>
            We may review, scan, or analyze your communications with Free Your Kitchen for reasons
            outlined in Article 3 of this policy, including fraud prevention, risk assessment, regulatory
            compliance, investigation, product development, research, analytics, enforcing our Terms of
            Service, and customer support purposes. In some cases, we may also scan, review, or analyze
            messages to debug, improve, and expand product offerings. We use automated methods where
            reasonably possible. Occasionally we may need to manually review communications, such as for
            fraud investigations and customer support, or to assess and improve the functionality of these
            automated tools.
          </p>
          <h5>5.2 Third-Party Partners & Integrations.</h5>
          <p>
            Parts of the Free Your Kitchen Platform may integrate with or link to third-party services, not
            owned or controlled by Free Your Kitchen, such as Google Maps/Earth or Stripe. Use of these
            services is subject to the privacy policies of those providers. Free Your Kitchen does not own or
            control these third parties and when you interact with them you are providing your information to
            them, and thereby agree to the privacy guidelines of such third parties.
          </p>

        </section>
        <section>
          <h2>6. YOUR RIGHTS</h2>
          <p>
            You can exercise any of the rights described in this section consistent with applicable law. Please
            note that we may ask you to verify your identity and request before taking further action on your
            request.
          </p>
          <h5>6.1 Data Access and Corrections.</h5>
          <p>
            You can access and update some of your Information by contacting Free Your Kitchen and
            submitting a request. You are responsible for keeping your Information up to date. You can
            request a correction to your Information if you believe it is inaccurate. If you have submitted
            Information and would like to have access to it, or if you would like to have it corrected, please
            contact us using the contact information provided below.
          </p>
          <h5>
            6.2 Data Erasure.
          </h5>
          <p>In certain jurisdictions, you can request that your Information be deleted, or you may withdraw
            consent at any time. Please note that if you withdraw consent or request the erasure of your
            Information:</p>
          <ul style={{ listStyle: "initial" }}>
            <li>
              We may retain your Information as necessary for our legitimate business interests, such
              as prevention of money laundering, fraud detection and prevention, and enhancing safety.
              For example, if we suspend you from using Free Your Kitchen Platform for fraud or safety
              reasons, we may retain information gathered from you to prevent you from using Free
              Your Kitchen Platform in the future.
            </li>
            <li>
              We may retain and use your Information to the extent necessary to comply with our legal
              obligations. For example, Free Your Kitchen may keep information for tax, legal reporting
              and auditing obligations.
            </li>
            <li>
              Information you have shared with others (such as reviews, comments, and forum postings)
              may continue to be publicly visible on Free Your Kitchen, even after your Free Your
              Kitchen Account is cancelled, suspended or voluntarily deleted. Some copies of your
              Information (such as log records) may remain in our database.
            </li>
            <li>Because we take measures to protect data from accidental or malicious loss and
              destruction, residual copies of your Information may not be removed from our backup
              systems for a limited period of time.</li>

          </ul>
        </section>
        <section>

          <h2>7. SECURITY</h2>
          <p>While no organization can guarantee perfect security, we are continuously implementing and
            updating administrative, technical, and physical security measures to help protect your
            Information against unauthorized access, loss, destruction, or alteration.</p>
        </section>

        <section>
        <h2>8. Payment Processing: Apple Pay and/or Google Pay</h2>

        <p>
          We offer secure and convenient payment options through Apple Pay and Google Pay in our app. When you make a payment, rest assured that we do not store any payment details (such as credit card numbers or billing information) on our servers or within the app.<br/>
          All payment transactions are securely processed by Stripe, our third-party payment processor, which adheres to industry-leading security standards. Stripe manages sensitive payment information such as credit card numbers on our behalf, and we do not collect or store this information directly.<br/><br/>

          Data Collected by Apple Pay:<br/>

            •	When you use Apple Pay, Apple collects certain information necessary to complete the transaction, such as your device and account details, as outlined in their <a href="https://www.apple.com/legal/applepayments/privacy-notice/">Apple Pay privacy policy</a>. Apple does not share your credit card information with us.<br/>

            Data Collected by Google Pay:<br/>

            •	When you make payments using Google Pay, Google collects the necessary information to complete the transaction. This data is processed according to their <a href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en-GB">Google Pay privacy policy</a>. Your payment information (such as credit card numbers) is not shared with us.<br/>

          Data Collected by Stripe:<br/>

            •	Stripe may collect and process personal information necessary to complete the transaction, such as your name, email, and billing information. This data is processed securely and in compliance with applicable laws and privacy regulations. For more details, you can refer to <a href="https://support.stripe.com/topics/privacy">Stripe’s privacy policy</a>.<br/><br/>

          By using Apple Pay in our app, you acknowledge that the processing of payments is conducted by Stripe, and Apple Pay may process your data according to their own terms and privacy policies.
        </p>
        </section>
        <section>
          <h2>9. CHANGES TO THIS PRIVACY POLICY</h2>
          <p>Free Your Kitchen reserves the right to modify this Privacy Policy at any time in accordance with
            this provision. If we make changes to this Privacy Policy, we will post the revised Privacy Policy
            on the Free Your Kitchen Platform. Changes to this Privacy Policy shall be effective upon posting,
            and you understand and agree that if you use the Free Your Kitchen Platform after the date on
            which the Privacy Policy has changed, Free Your Kitchen will treat your access to or use of the
            Free Your Kitchen Platform as acceptance of the revised Privacy Policy. If you disagree with the
            revised Privacy Policy, you may terminate this Agreement in accordance with the Terms of Use.
            If you do not terminate this Agreement, your continued access to or use of the Free Your Kitchen
            Platform or Free Your Kitchen Services will constitute acceptance of and be subject to the revised
            Privacy Policy.</p>
        </section>
        <section>
          <h2>10. CONTACT INFORMATION AND RESPONSIBLE FREE YOUR KITCHEN ENTITIES</h2>
          <p>If you have any questions regarding this Privacy Policy or Free Your Kitchen’s handling of your
            Information, please contact us at 140 Yonge St, 224, Toronto, Ontario, Canada, M5C1X6.</p>
        </section>
        {/* <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:
          </p>
          <p>
            Email: <a href="mailto:info@freeyourkitchen.com">info@freeyourkitchen.com</a>
          </p>
          <p>
            <b>Address:</b><br />
            Free Your Kitchen<br />
            140 Yonge Street 224<br />
            Toronto, Ontario - Canada M2R 1X6.<br />
          </p>
          <p>
            Thank you for using Free Your Kitchen! Your privacy and trust are important to us.
          </p>
        </section> */}
      </main>
      <Landing />
    </div>
  );
};
export default PrivacyPolicy;